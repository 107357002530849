<template>
	<div>
		<!-- 按钮部分 -->
		<div class="button" style="margin: 20px 0 20px 50px;">
			<el-button type="primary" @click="$router.back()">返回</el-button>
			<el-button type="primary" :loading="loading" @click="submitForm(defForm)">保存</el-button>
		</div>
		<!-- 第一列 -->
		<el-form class="left" ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="景区名称" prop="title">
				<el-input v-model="defForm.title"></el-input>
			</el-form-item>
			<el-form-item label="景区等级" prop="level">
				<el-input v-model="defForm.level"></el-input>
			</el-form-item>
			<el-form-item label="景区电话" prop="phone">
				<el-input v-model="defForm.phone"></el-input>
			</el-form-item>
			<el-form-item label="景区视频" prop="video">
				<el-upload 
					class="avatar-uploader" 
					action="上传地址" 
					:on-progress="uploadVideoProcess" 
					:on-success="handleVideoSuccess"
					:before-upload="beforeUploadVideo" 
					:http-request="uploadVideo"
					:show-file-list="false">
					<video 
						v-if="defForm.video !='' && !videoFlag" 
						:src="defForm.video"
						class="avatar video-avatar" controls="controls">
						您的浏览器不支持视频播放
					</video>
					<i v-else-if="defForm.video =='' && !videoFlag" class="el-icon-plus avatar-uploader-icon"></i>
					<el-progress v-if="videoFlag == true" type="circle" v-bind:percentage="videoUploadPercent" style="margin-top:7px;"></el-progress>
				</el-upload>
			</el-form-item>
		</el-form>
		<!-- 第二列 -->
		<el-form class="right" ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="父级栏目" prop="channel_id">
				<treeselect v-model="defForm.channel_id" placeholder="请选择栏目" :multiple="false" :options="treeData" />
			</el-form-item>
			<el-form-item label="景区地址" prop="address">
				<el-input v-model="defForm.address"></el-input>
			</el-form-item>
			<el-form-item label="景区链接" prop="link">
				<el-input v-model="defForm.link"></el-input>
			</el-form-item>
			<el-form-item label="景区图片" prop="image">
				<el-upload 
					class="avatar-uploader" 
					action="string" 
					:show-file-list="false" 
					:http-request="uploadImage"
					:before-upload="beforeAvatarUpload">
					<img v-if="defForm.image" :src="defForm.image" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
		</el-form>
		<!-- 编辑器 -->
		<el-form ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="景区详情" prop="content">
				<v-md-editor v-model="defForm.content" height="300px"></v-md-editor>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import {
		create,
		uploadImage,
		uploadVideo
	} from '@/api/scenic'
	import {
		tree
	} from '@/api/channel'
	import token from "@/token.js";
	export default {
		name: "Add",
		components: {
			Treeselect
		},
		data() {
			return {
				defForm: {
					title: '',
					channel_id: null,
					level: '',
					address: '',
					phone: '',
					link: '',
					video: '',
					image: '',
					content: '',
					createUserID: token.getUser().id,
					createUserName: token.getUser().manager_username
				},
				treeData: [],
				loading: false,
				rules: {
					title: [{
						required: true,
						message: '必填项不能为空'
					}],
					channel_id: [{
						required: true,
						message: '必填项不能为空'
					}],
				},
				videoFlag: false,//是否显示进度条
				videoUploadPercent: "",//进度条的进度，
				isShowUploadVideo: false,//显示上传按钮
			}
		},

		created() {
			tree().then(data => {
				this.treeData = data.data
				console.log(this.treeData)
			}).catch(error => {
				this.$message.error(error)
			})
		},
		methods: {
			submitForm(form) {
				this.$refs['defForm'].validate(valid => {
					if (valid) {
						this.loading = true
						if (this.defForm.channel_id == null) {
							this.defForm.channel_id = 0;
						}
						// console.log("新增",this.defForm)
						create(this.defForm).then(data => {
							this.loading = false
							this.$refs['defForm'].resetFields()
							this.$message.success("景区新增成功!")
							this.$router.push('/admin/business/scenicindex')
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			},
			close() {
				this.$emit("hideDialog")
			},
			//上传前回调
			beforeUploadVideo(file) {
				var fileSize = file.size / 1024 / 1024 < 100;
				if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
					this.$message.error('请上传正确的视频格式!')
					return false;
				}
				if (!fileSize) {
					this.$message.error('视频大小不能超过100MB!')
					return false;
				}
				this.isShowUploadVideo = false;
			},
			//进度条
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.videoUploadPercent = file.percentage.toFixed(0) * 1;
			},
			//上传成功回调
			handleVideoSuccess(res, file) {
				this.isShowUploadVideo = true;
				this.videoFlag = false;
				this.videoUploadPercent = 0;
			},
			uploadVideo(param) {
				let formData = new FormData();
				formData.append("file", param.file)
				uploadVideo(formData).then(data => {
					this.defForm.video = data.data
					console.log(this.defForm.video)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			uploadImage(param) {
				let formData = new FormData();
				formData.append("file", param.file)
				uploadImage(formData).then(data => {
					this.defForm.image = data.data
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//上传之前的验证
			beforeAvatarUpload(file) {
				const isIMAGE = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png')
				if (!isIMAGE) {
					this.$message.error('上传文件只能是图片格式!')
				}
				return isIMAGE
			},
		}
	}
</script>

<style scoped>
	.left {
		width: 50%;
		display: inline-block;
	}

	.right {
		width: 50%;
		display: inline-block;
	}

	.avatar-uploader {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		width: 80px;
		height: 80px;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}

	.avatar {
		width: 80px;
		height: 80px;
		display: block;
	}
</style>
